.sendBut, .disabledBut{
    cursor: pointer;
    position: absolute;
    z-index: 100;
    right: 1.5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-left: 0.2vw solid #C4C4C4;
}

.sendBut img, .disabledBut img{
    margin-right: 0.5vw;
    width: 3vw;
    margin-left: 1vw;
}

.disabledBut{
    cursor: default;
    opacity: 0.4;
    
}

@media(max-width : 1024px){
    .sendBut, .disabledBut{
        cursor: pointer;
        position: absolute;
        z-index: 100;
        right: 6vw;
        
        height: 100%;
        border-left: 0.1vw solid #C4C4C4;
    }
    
    .sendBut img, .disabledBut img{
        width: 8vw;
        margin-top: -1vh;
        margin-left: 5vw;
    } 
}
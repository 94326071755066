.commonHeader{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    z-index: 1000000;
    background-color: white;
    border-bottom: 1vh solid #92E3DA;
    color: black;
    min-height: 8.5vh;
}

.mockHeader{
    width: 100%;
    min-height: 8.5vh;

}

.centerPartHeader{
    width: 50%;
    justify-content: end;
    align-items: center;
    display: flex;
}

.burgerMenuHeader, .burgerMenuHeaderActive{
    padding: 0.5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3vw;
}

.burgerMenuHeaderActive{
    background-color: #5F8BD9;
    border-radius: 3vw;
}

.headerHeading{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.8vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.4vw;
    min-width: 10vw;
}

.bringImgContainer{
    margin-right: 1.3vw;
}
.bringImgContainer img {
    width: 1.6vw;
}

.rightPartHeader{
    width: 50%;
    align-items: center;
    justify-content: flex-end;
    display: flex;
    margin-right: 2vw;
}

.pesronImg{
    margin-left: 1vw;
    cursor: pointer;
}

.pesronImg img{
    width: 2.6vw;
    border-radius: 3vw;
}

.leftPartHeaderChat{
    margin-left: 4.3vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logoLink{
    margin-left: 4.5vw;
}

.logoLink img{
    width: 13vw;
}

.chatsHeader{
    cursor: pointer;
    margin-right: 2vw;
}

.settingsHeader{
    margin-right: 2vw;
    cursor: pointer;
}

.chatsHeader img{
    width: 2.5vw;
    padding-top:0.4vw;
}

.burgerMenuHeader, .burgerMenuHeaderActive{
    cursor: pointer;
}

.burgerMenuHeader img, .burgerMenuHeaderActive img{
   width: 2vw;
}

.settingsHeader img{
    padding-top: 0.3vw;
    width: 2.8vw;
}

@media (max-width: 1024px){
    .headerHeading{
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-size: 5.7vw;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0.4vw;
    }

    .burgerMenuHeader{
        margin-left: -18vw;
        margin-bottom: 0.5vh;
        display: block;
    }

    .burgerMenuHeader img {
        width: 2vw;
    }

    .rightPartHeader{
        display: none;
    }

    .leftPartHeaderChat img {
        width: 7vw;
    }

    .leftPartHeaderChat{
        margin-left: 0;
    }

    .logoLink{
        position: absolute;
        top: 1.5vh;
        margin-left: 35vw;
        bottom: 0;
    }

    .logoLink img{
        width: 30vw;
    }

    .commonHeader{
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 8vh;
        background-color: white;
        color: white;
        border: none;
        box-shadow: 0px 8px 16px rgba(110, 120, 130, 0.12);
    }

    .settingsHeader img{
        padding-top: 1.5vw;
        width: 7vw;
    }
}

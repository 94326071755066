.PopUpPrompt{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100000 !important;
    background-color: rgba(0, 0, 0, 0.65);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  
  .footerPopUpPrompt{
    margin-top: 1vh;
    height: 5vh;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: black;
  }

 
  
  .footerPopUpPrompt div{
    background-color: white;
    width: 2vw;
    border-radius: 3vw;
    cursor: pointer;
    font-size: 1.3vw;
    margin-right: 1vw;
  }
  
  .bodyPopUpPrompt{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;
    color: black;
    border-bottom: 0.5px solid lightgray;
    height: 20vh;
    font-size:1.4vw;
    font-weight: bold;
  }
  
  .PopUpInsidePrompt{
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    border-radius: 10px;
    width: 25vw;
    height: 30vh;
    z-index: 1000!important;
  
  }


  @media only screen and (min-width: 100px) and (max-width : 980px) {

    .PopUpInsidePrompt{
      background-color: rgba(255, 255, 255, 1) !important;
      display: flex;
      flex-direction: column;
      box-shadow: 0 0 10px rgba(0,0,0,0.5);
      border-radius: 10px;
      height: 20vh;
      width: 60vw !important;
      z-index: 1000!important;
      
    }

    .footerPopUpPrompt div{
      background-color: white;
      width: 2vw;
      border-radius: 3vw;
      cursor: pointer;
      font-size: 3.5vw;
      margin-right: 6vw;
      margin-bottom: 1.5vh;
      
    }
    
    .bodyPopUpPrompt{
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 10px;
      color: black;
      border-bottom: 0.5px solid lightgray;
      height: 13vh;
      font-size:4vw;
      font-weight: bold;
    }

    

  }
 

 
  
  

 
.firstPartHeaderChart{
    display: flex;
    margin-top :  0.1vh;
}

.firstPartHeaderChart > :first-child {
    text-align: start;
    font-size: 1.6vw;
}

.chartHeaderPeriodText{
    font-size: 1.2vw;
    margin-right: 0.3vw;
}

.firstPartHeaderChart > :last-child {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-left: 17vw;
}

.headerChart{
    position: relative;
}

.firstPartHeaderChartImageBlock{
    position: absolute;
    right: 0.1vw;
    margin-top: -0.5vh;
}

.firstPartHeaderChart img {
    width: 2.5vw;
    cursor: pointer;
}

.secondPartHeaderChart{
    margin-top: 2vh;
    margin-bottom: 1vh;
    display: flex;
}

.secondPartHeaderChart select {
    margin-top: 1vh;
    color: #5F8BD9;
    border: none !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0;
}

.secondPartHeaderChart select option {
    color: black;
}

.secondPartHeaderChart  :last-child{
    margin-top: -1vh;
    margin-left: 0.1vw;
}
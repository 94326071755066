
.PopUpContainerBackground{
    width: 100vw;
    position: fixed !important;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 10000000000000000000 !important;
    background-color: rgba(0, 0, 0, 0.85);
    display: flex;
    justify-content: center;
    align-items: center;
}

.ClosePopUpContainerBackground {
    position: absolute;
    top: 2vh;
    right: 2.5vw;
    cursor: pointer;
}

.ClosePopUpContainerBackground img {
    width: 2.5vw;
}

@media (max-width: 1024px){
    .ClosePopUpContainerBackground img {
        width: 7vw;
    }
}

body{
    margin: 0;
}

.other, .user{
    position: relative;
    font-size: 1.05vw;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    color: black;
    word-wrap: break-word;
    width: fit-content;
    max-width: 65%;
    padding-top: 1.5vh;
    padding-bottom: 2vh;
}

.other{
    border-radius: 20px 20px 20px 0;
}

.user{
    border-radius: 20px 20px 0 20px;
    margin-left: auto;
    cursor: pointer;
    word-break: break-all;
}

.other p, .user p {
    padding: 6px 16px;
    width: fit-content;
    max-width: 100%;
    margin: 0;
}

.messageBlockContentContainer {
    width: fit-content;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.other .messageBlockContentContainer {
    background-color: #F3F3F3;
    border-radius: 20px 20px 20px 0;
}

.user .messageBlockContentContainer {
    background-color: #92E3DA;
    border-radius: 20px 20px 0 20px;
}

.commandContainer {
    background-color: #5F8BD9 !important;
    color: white !important;
}

.avatarPartOther, .avatarPartUser{
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
}

.avatarPartOther {
    padding-right: 1vw;
}

.avatarPartUser {
    padding-left: 1vw;
}

.avatarPartOther img, .avatarPartUser img {
    width: 48px;
    height: 48px;
}

.avatarPartUser img {
    border-radius: 20vw;
}

.messageBlockUserName{
    margin-bottom: -21px;
    margin-top: -1vh;
    font-size: 0.9vw;
    color: lightblue;
}

.imageMessage{
    display: flex;
    justify-content: center;
    align-items: center;
}

.imageMessage img {
    width: 40px;
    margin: 0 0 1vh 2vw;
}

.messageBlockEdit, .messageBlockDelete{
    position: absolute;
    width: 42% !important;
    justify-content: center;
    align-items: center;
    top: 0vh;
    background-color: whitesmoke;
    display: none;
    height: 60% !important;
}

.messageBlockEdit{
    left: 0;
    border-radius: 0.4vw 0 0 0.4vw;
}

.messageBlockDelete{
    right: 0;
    border-radius: 0 0.4vw 0.4vw 0;
}

.messageBlockEdit:hover, .messageBlockDelete:hover{
    background-color: lightgray;
}

.messageBlockEdit img, .messageBlockDelete img{
    width: 1.8vw;
}

.user:hover .messageBlockEdit, .user:hover .messageBlockDelete{
    transition: 0.5s;
    display: flex;
}

@media (max-width: 1024px){
    .other, .user {
        font-size: 3.5vw;
    }
    
    .messageBlockContentContainer {
        padding: 1vh 3vw 1vh 3vw;
    }

    .messageBlockUserName{
        margin-top: 0.1vh;
        margin-bottom: -0.5vh;
        font-size: 3.6vw;
        color: lightblue;
    }

    .imageMessage img {
        width: 14vw;
    }

    .avatarPartOther img, .avatarPartUser img{
        width: 8vw;
        height: 8vw;
    }
}


.options{
    display: flex;
    flex-direction: row;
}

.userImageAction {
    display: flex;
    align-items: center;
    padding-top: 0.5vh;
}

.userImageAction img {
    width: 3.2vw;
}

.blockOption{
    display: flex;
    align-items: center;
    background-color: #5F8BD9;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.2vh;
    min-width: 5vw;
    padding-left: 1vw;
    padding-right: 1vw;
    margin-right: 1vw;
    border-radius: 20px;
}

.blockOption label {
    cursor: pointer;
    color: white;
}

.selectMessage {
    justify-content: center;
    align-items: flex-end;
    max-width: 100% !important;
    font-size:1.05vw;
    display: flex;
    padding: 1vw;
    flex-direction: column;
    max-width: 37%;
    margin-bottom: 1.5vh;
    position: relative;
    margin-top: 1.2vh;
    border-radius: 0.4vw;
    color: black;
    word-wrap: break-word;
}

.userImageAction img{
    border-radius: 100vw;
}


.radioButton{
    -ms-transform: scale(1.1); /* IE 9 */
    -webkit-transform: scale(1.1); /* Chrome, Safari, Opera */
    transform: scale(1.1);
}

.radioButton:active{
    outline: none;
}

@media(max-width : 1024px){

    .options{
        flex-direction: column;
    }
    .selectMessage {
        font-size: 3.6vw;
        display: flex;
        padding: 2vw;
        flex-direction: column;
        max-width: 50%;
        margin-bottom: 1.5vh;
        position: relative;
        margin-left: 60%;
        margin-top: 1.2vh;
        border-radius: 0.4vw;
        background-color: white;
        word-wrap: break-word;
    }

    .blockOption{
        display: flex;
        align-items: center;
        background-color: #5F8BD9;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1.2vh;
        box-sizing: border-box;
        min-width: 5vw;
        padding: 0.8vh 5vw;
        margin-right: 1vw;
        border-radius: 5px;
    }

    .userImageAction {
        display: none;
    }
    

    .radioButton{
        margin-left: 2.1vw;
        -ms-transform: scale(2.5); /* IE 9 */
        -webkit-transform: scale(2.5); /* Chrome, Safari, Opera */
        transform: scale(2.5);
    }
}
.chatBox{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.chatArea{
    width: 100%;
    justify-content: end;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    height: 73vh;
    box-sizing: border-box;
}

.chatArea::-webkit-scrollbar { width: 0.4vw; height: 0;}
.chatArea::-webkit-scrollbar-track-piece { background-color: white;}
.chatArea::-webkit-scrollbar-thumb { height: 0.1vh; background-color: lightgrey; border-radius: 1vw;}

.otherBand, .userBand{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.1vw;
    cursor: default;
}

.userBand{
    margin-left:95%;
    margin-top: 2vh;
}

.otherBand{
    margin-top: 2vh;
    margin-left: 1%;
}

.insideChatArea{
    width: 100%;
    max-height: 100%;
    position: relative;
}

.searchMes{
    top: -5.5%;
    z-index: 100;
    position: absolute;
    height: 4vh;
    width: 100%;
    border-bottom:0.1vw solid #798F96;
}

@media (max-width: 1024px){
    .chatArea{
        height: 78vh;
        border: none;
        padding: 2vw;
    }
}
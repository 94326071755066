.commandButton{
    cursor: pointer;
    position: absolute;
    z-index: 100;
    right: 7.6vw;
    height: 100%;
    border-left: 0.2vw solid #C4C4C4;
}



.actionImage, .closeImage {
    width: 3vw;
    margin-top: 2.3vh;
    margin-left: 1.5vw;
}




@media(max-width : 1024px){
    .commandButton{
        cursor: pointer;
        position: absolute;
        z-index: 100;
        right: 26vw;
        
        height: 100%;
        border-left: 0.1vw solid #C4C4C4;
    }
    
    .commandButton img{
        width: 8vw;
        margin-top: 3vh;
        margin-left: 6vw;
    } 
}


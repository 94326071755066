.DateInput{
    margin-top: 3vh;
    margin-bottom: 3vh;
    caret-color : black;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    width: 100% !important;
    position: relative;
}

.DateInput > :last-child{
    width: 100%;
}

.CloseCalendarContainer {
    position: absolute;
    top: 4vh;
    right: 2vw;
    cursor: pointer;
}

.CloseCalendarContainer img {
    width: 3vw;
}


.CalendarContainer{
    width: 100vw;
    position: fixed !important;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 10000000000000000000 !important;
    background-color: rgba(0, 0, 0, 0.85);
    display: flex;
    justify-content: center;
    align-items: center;
}

.DateInputImgContainer{
    position: absolute;
    right: 0.1vw;
    bottom: 0.2vh;
}

.DateInputImgContainer img {
    width: 1.8vw !important;
}

.DateInput input {
    color: black !important;
    box-sizing: border-box;
    border: none;
    border-bottom: 0.1vw solid #C4C4C4;
    background-color: inherit;
    width: 100% !important;
    color: white;
    padding-left: 1vw;
    padding-bottom: 0.5vh;
    caret-color: #C4C4C4;
    font-size: 1.3vw;
    font-weight:lighter;
    z-index: 1;
    cursor: pointer;
}

@media (max-width: 1024px){
    input:-webkit-autofill {
        -webkit-box-shadow: inset 0 0 0 50px white!important; /* Цвет фона */
        -webkit-text-fill-color: lightgrey !important; /* цвет текста */
    }
    .DateInput{
        margin-top: 3vh;
        margin-bottom: 3vh;
        position: relative !important;
        caret-color : rgb(165, 142, 142);
    }

    .DateInput input {
        box-sizing: border-box;
        border: none;
        border: 0.1vw solid #C4C4C4;
        background-color: inherit;
        width: 100%;
        color: white;
        padding: 3vw;
        caret-color: #798F96;
        font-size: 4.5vw;
        border-radius: 1.5vw;
        font-weight:lighter;
        z-index: 1;
    }

    .DateInputImgContainer{
        right: 3vw;
        bottom: 0.9vh;
    }

    .DateInputImgContainer img{
        width: 8vw !important;
    }

    .CloseCalendarContainer {
        right: 5vw;
    }

    .CloseCalendarContainer img {
        width: 10vw;
    }


}
.Button, .DisabledButton{
    font-size: 1.1vw;
    margin-top: 2.5vh;
    width: 100%;
    padding: 1vw;
    padding-left: 0;
    padding-right: 0;
    background-color: inherit;
    border: 0.1vw solid #798F96;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.DisabledButton{
    opacity: 0.5;
    cursor: default;
}

.Button:hover{
    background-color: #798F96;
    transition: 0.5s;
}
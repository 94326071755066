.Input, .NotActiveInput{
    margin-top: 3vh;
    margin-bottom: 3vh;
    position: relative;
    caret-color : black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100% !important;
}

.NotActiveInput{
    color: lightgray !important;
}

.NotActiveInput input {
    color: lightgray !important;
    border-bottom: 0.1vw solid lightgray;
    cursor: default;

}




.InputArea, .InValidInputArea{
    color: black !important;
    box-sizing: border-box;
    border: none;
    border-bottom: 0.1vw solid #C4C4C4;
    background-color: inherit;
    width: 100% !important;
    color: white;
    padding-left: 1vw;
    padding-bottom: 0.5vh;
    caret-color: #C4C4C4;
    font-size: 1.3vw;
    font-weight:lighter;
    z-index: 1;
}

.InValidInputArea{
    border-bottom: 0.1vw solid #dc3545;
}

.InputArea:focus, .InValidInputArea:focus{
    outline: none;
    color: black;
}



.InputPlaceholder{
    display: flex;
    position: absolute;
    flex-direction: row !important;
    justify-content: start !important;
    align-items: center;
    left: 1vw;
    width: 100% !important;
    bottom: 0.5vh;  
    font-size: 1.1vw; 
    opacity: 0.6;
}

.InputPlaceholder :last-child{
    margin-top: 1vh;
}

.InputPlaceholder img{
    width: 2.8vw;
    margin-right: 0.5vw;
}

.Input input :focus{
    border-bottom: 0.1vw solid #92E3DA;
}

.ErrorPromptInput{
    position: absolute;
    top: 4.1vh;
    color: #dc3545;
    font-size: 0.72vw;
    font-weight: bold;
    width: 100%;
}

.eyeInput{
    position: absolute;
    top: -0.1vh;
    right: 0.5vw !important;
    z-index: 100;
    bottom: 0;
    right: 0;
    cursor: pointer;
}

.eyeInput img{
    width: 1.85vw;
}


input {outline: none;}
input:-webkit-autofill {
    -webkit-box-shadow: inset 0 0 0 50px white!important; /* Цвет фона */
    -webkit-text-fill-color: black !important; /* цвет текста */
}

@media (max-width: 1024px){
    input:focus{
        border: 0.1vw solid #92E3DA;
    }
    input:-webkit-autofill {
        -webkit-box-shadow: inset 0 0 0 50px white!important; /* Цвет фона */
        -webkit-text-fill-color: lightgrey !important; /* цвет текста */
    }
    .Input, .NotActiveInput{
        margin-top: 3vh;
        margin-bottom: 3vh;
        position: relative !important;
        caret-color : rgb(165, 142, 142);
           
    }
    .InputArea, .InValidInputArea{
        box-sizing: border-box;
        border: none;
        border: 0.1vw solid #C4C4C4;
        background-color: inherit;
        width: 100%;
        color: white;
        padding: 3vw;
        caret-color: #798F96;
        font-size: 4.5vw;
        border-radius: 1.5vw;
        font-weight:lighter;
        z-index: 1;
    }

    .eyeInput{
        position: absolute !important;
        top: 1.5vh;
        left: 79vw;
        z-index: 100;
        bottom: 3vh;
        right: 0;
        cursor: pointer;
    }
    
    .eyeInput img{
        width: 7vw;
    }

    .InValidInputArea{
        border: 0.2vw solid #dc3545;
    }


    .InputPlaceholder img{
        width: 3vw;
        margin-right: 0.5vw;
        
    }
    
    .ErrorPromptInput{
        position: absolute;
        top: 7vh;
        color: #dc3545;
        font-size: 2.55vw;
        width: 100%;
    }
 
    .InputPlaceholder :last-child{
        margin-bottom: 1vh;
    }

    .InputPlaceholder{
        position: absolute;
        flex-direction: row !important;
        justify-content: start !important;
        align-items: center;
        left: 2vw; 
        bottom: 4vh; 
        top: 3vh; 
        font-size: 4vw; 
        opacity: 0.6;
    }

    

    .InputPlaceholder img{
        width: 6vw;
        margin-right: 2vw;
    }
    
}

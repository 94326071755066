.ChartBlockCommon{
    position: relative;
    padding-left: 3vw;
}

.ImageChartY{
    position: absolute;
    left: 0;
}

.ImageChartY img {
    width: 3vw;
}



.linkContainer {
    margin-left: 0.5em;
}

.linkContainer > * {
    font-size: 1.05em;
}

.linkContainer > a {
    color: #5F8BD9;
    text-decoration: none;
}
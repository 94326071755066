.PersonalReports{
    padding: 6vh 2vw 4vh 10vw;
}

.PersonalReportsHeadingText{
    margin-left: 1vw;
    font-size: 1.6vw;
    text-transform: uppercase;
}

.PersonalReportsCouple{
    width: 100%;
    display: flex;
    margin-bottom: 5vh;
}

.PersonalReportsCouple > :first-child{
    width: 55%;
}

.waitChartBlock{
    padding: 3vh 1vw;

}

.chartBlock{
    box-sizing: border-box;
    padding: 2vh 1.5vw;
    width: 100%;
}

.PersonalReportsCouple > :last-child{
    width: 42%;
    margin-left: 3%;
}

.adUnitImage{
    width: 100%;
}

.coupleAdInitContainer{
    display: flex;
    margin-top: 2vh;
}

.coupleAdInitContainer > :first-child {
    width: 58%;
}

.coupleAdInitContainer > :last-child {
    width: 40.5%;
    margin-left: 1.5%;
}


@media (min-width: 1920px){

    .PersonalReports{
        padding: 6vh 7vw 4vh 15vw;
    }
    

    .PersonalReportsCouple > :first-child{
        width: 50%;
    }

    .PersonalReportsCouple > :last-child{
        width: 47%;
        margin-left: 3%;
    }
        
}

@media (max-width: 1024px){

    .PersonalReportsCouple{
        width: 100%;
        display: flex;
        flex-direction: column !important;
        margin-bottom: 5vh;
    }

    .PersonalReportsCouple > :first-child{
        width: 100%
    }

    .PersonalReportsCouple > :last-child{
        width: 100%;
        margin: 0;
    }

    .PersonalReports{
        padding: 2vh 2vw;
    }

    .PersonalReportsHeadingText{
        margin-left: 1vw;
        font-size: 3.5vw;
    }


    .coupleAdInitContainer{
        display: flex;
        flex-direction: column;
        margin-top: 2vh;
    }
    
    .coupleAdInitContainer > :first-child {
        width: 100%
    }
    
    .coupleAdInitContainer > :last-child {
        width: 100%;
        margin : 0;
    }

    .PersonalReportsCouple{
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 1vh;
    }
    
    .PersonalReportsCouple > :first-child{
        width: 100%;
    }

    .PersonalReportsCouple > :last-child{
        width: 100%;
        margin: 0 !important;
    }

    .chartChildrenBox{
        display: none;
    }
}
.input__wrapper {
    width: 100%;
    position: relative;
  }
   
  .input__file {
    opacity: 0;
    visibility: hidden;
    position: absolute;
  }
   


   
  .input__file-button {
    margin-top: 2.4vh;
    font-size: 1vw;
    width: 100%;
    box-sizing: border-box;
    padding: 2vh 1vw;
    background-color: #5F8BD9 !important;
    border: 0.1vw solid #5F8BD9 !important;
    display: flex;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-align: center;
    color: white;
    
  }

  @media (max-width: 1024px){
    .input__file-button {
        -webkit-appearance: none;
        font-size: 5vw !important;
        padding: 2.1vh;
        box-sizing: border-box;
        
        background-color: #5F8BD9 !important;
        border: 0.1vw solid #5F8BD9 !important;
        display: flex;
        border-radius: 1vw;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
}
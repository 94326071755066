.BoxPersonalReport{
    background: #FFFFFF;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    border-radius: 0.5vw;
    width: 100%;
    margin-bottom: 1vh;
}

@media (max-width: 1024px){
    .BoxPersonalReport{
        background: #FFFFFF;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
        border-radius: 0.5vw;
        width: 100%;
        margin-bottom: 2vh;
        margin-top: 2vh;
        font-size: 3vw;
    }
}
.PersonalReportRecomendationBlock{
    padding: 2vw 3vh;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.TextPersonalReportRecomendationBlock{
    font-size: 1vw;
    line-height: 25px;
}

.HeadingPersonalReportRecomendationBlock{
    margin-bottom: 3vh;
    font-size: 1.6vw;
}

@media (max-width: 1024px){
    .PersonalReportRecomendationBlock{
        padding: 1vh 1vw;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
    }
    
    .TextPersonalReportRecomendationBlock{
        font-size: 4vw;
        line-height: inherit;
    }
    
    .HeadingPersonalReportRecomendationBlock{
        margin-bottom: 1vh;
        font-size: 3.5vw;
    }
}
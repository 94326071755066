.mesTextArr{
    width: 100%;
    font-size: 1vw;
    min-height: 7vh;
    resize: none;
    padding: 1vw;
    padding-right: 12.4vw;
    color: whitesmoke;
    border-radius: 0 0 0.5vw 0.5vw;
    background-color: #F3F3F3;
    border: none;
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
    color: black;
    border-radius: 10px;
}

.mesTextArr:focus{
    outline: none
}

@media(max-width : 1024px){
    .mesTextArr{
        width: 100%;
        font-size: 3.8vw;
        height: 10vh;
        resize: none;
        padding: 1vw;
        padding-right: 42vw;
        padding-top: 1vh;
        padding-left: 2vw;
        border-top: 0.1vw solid #c4c4c4;
        color: black;
        background-color: white;
    }
}
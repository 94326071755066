.PersonalReportImageBlock{
    font-size: 1vw;
    padding: 1vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.PersonalReportImage {
    width: 14vw;
}

.PersonalReportHintImageBlock {
    margin-right: 0.75vw;
    margin-left: auto;
    width: 2vw;
    height: 2vw;
}

.PersonalReportHintImageBlock img {
    width: 32px;
    height: 32px;
}

#personalReportHint {
    border-radius: 5px;
    padding: 1vw 0 1vw 1vw;
    font-size: 0.9vw;
    background-color: white;
    box-shadow: 0px 2px 10px #E4E4E4;
    display: none;
    position: relative;
    width: 30vw;
    right: 32vw;
    top: 0px;
}

#personalReportHint > :first-child {
    margin-bottom: 0.5em;
}

#personalReportHint > p {
    margin: 0.2em;
}

@media (max-width: 1024px){
    .PersonalReportImageBlock{
        font-size: 4.5vw;
        padding: 1vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .PersonalReportHintImageBlock {
        margin-right: 2vw;
    }

    .PersonalReportImage {
        width: 50vw;
    }
    
    #personalReportHint {
        font-size: 3vw;
        width: 80vw;
        right: 82vw;
    }
}

@font-face {
    font-family: Quicksand;
    src: url(../../Media/Fonts/Quicksand-Light.otf);
}

.chat{
    height: 100vh;
    width: 100%;
    overflow-y: hidden;
}

.rightSideChat{
    position: absolute;
    background-color: #f3f3f3;
    width: 8vw;
    height: 90vh;
    right: 0vw;
}

.activeChatBox{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 3vh;
}

.chatBox{
    width: 80%;
   
}



@media (max-width: 1024px){
    .chatBox{
        width: 100%;
    }

    .rightSideChat{
        display: none;
    }
}
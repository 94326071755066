.preloader{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
}

.spinerLogo img{
    width: 5vw;
}

@media(max-width : 1024px){
    .spinerLogo img{
        width: 13vw;
    }
}
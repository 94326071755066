.SettingsButton, .DisabledSettingsButton{
    font-size: 1.1vw;
    margin-top: 2.5vh;
    width: 100%;
    padding: 0.4vw;
    padding-left: 0.1vw !important;
    padding-right: 0.1vw !important;
    font-weight: normal;
    padding: 1.5vh 2vw;
    border: none;
    display: flex;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-align: center;
}

.DisabledSettingsButton{
    opacity: 0.5;
    cursor: default;
}

.SettingsButton:focus{
    border: none;
}



@media (max-width: 1024px){
    .SettingsButton{
        font-size: 4.2vw !important;
        margin-top: 5vh;
        width: 10vw;
        padding: 1vh 1vw !important;
        padding-left: 0;
        padding-right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
}

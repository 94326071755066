.controlChat{
    box-sizing: border-box;
    width: 100%;
    
    position: relative;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.1vw;
    display: flex;
    justify-content: center;
    position: relative;
    
}
.fullSideMenu, .hiddenSideMenu{
    background-color: white;
    position: fixed;
    width: 25vw; 
    height: 95vh;
    top: 0;
    z-index: 10000;
    padding-top: 6vh;
    box-shadow: 0.1vw 0 1vw rgba(0, 0, 0, 0.1);
}


.fullSideMenu .pointSideMenu, .fullSideMenu .activePointSideMenu {
    padding-left: 4.5vw !important;
}

.hiddenSideMenu {
    padding-left: 2vw;
    width: 6vw !important;
    background-color: #F3F3F3;
    border-radius: 0px 0px 1vw 1vw;
    box-shadow: none;
}


.logoSideMenuContainer{
    margin-bottom: 5vh;
    opacity: 0;
}

.logoSideMenuContainer img {
    width: 11vw;
}

.pointSideMenu, .activePointSideMenu{
    position: relative;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-size: 1.2vw;
    padding-left: 2.5vw !important;
    margin-bottom: 5vh;
    padding: 2vh 0;
    width: 100%;
    white-space: nowrap;
    box-sizing: border-box;
}



.activePointSideMenu {
    background-color: #5F8BD9;
    border-radius: 10px 0 0 10px;
    color: white !important;
    text-decoration: none;
}



.linkSideMenu {
    color: black;
}

.linkSideMenuActive {
    color: white;
}


.pointSideMenu :first-child, .activePointSideMenu :first-child{
    margin-right: 2vw;
}

.pointSideMenu img, .activePointSideMenu img{
    width: 1.8vw;
}


.closeSideMenuMobile{
    display: none;
}

.sideMenuFilter{
    display: none;
}

@media (max-width: 1024px){
    .fullSideMenu{
        position: relative;
        background-color: white;
        position: fixed;
        width: 80vw;
        box-sizing: border-box;
        height: 100vh;
        top: 0;
        z-index: 100000000000;
        padding-top: 6vh;
        color: black;
        box-shadow: 0.1vw 0 1vw rgba(0, 0, 0, 0.1);
    }

    .hiddenSideMenu {
        display: none;
    }

    .sideMenuFilter{
        display: block;
        position: fixed;
        top: 0;
        left: 80vw;
        width: 20vw;
        height: 100%;
        z-index: 1000001;
        background: rgba(0, 0, 0, 0.35);
    }

    .hiddenSideMenu .closeSideMenuMobile {
        display: none;
    }

    .fullSideMenu .closeSideMenuMobile{
        display: block;
        position: absolute;
        top: 1vh;
        right: 3vw;
    }

    .fullSideMenu .closeSideMenuMobile img {
        transform: rotate(45deg);
        width: 9vw;
    }
    
    
    .logoSideMenuContainer{
        margin-bottom: 3.5vh;
        opacity: 1;
        margin-left: 10vw;
    }
    
    .logoSideMenuContainer img {
        width: 40vw;
    }
    
    .pointSideMenu, .activePointSideMenu {
        position: relative;
        display: flex;
        align-items: center;
        margin-top : 3vh;
        font-size: 4vw;
        margin-bottom: 3vh; 
    }

    .activePointSideMenu {
        background-color: #5F8BD9;
        border-radius: 10px 0 0 10px;
        color: white !important;
        text-decoration: none;
    }
    
    
    .linkSideMenu, .linkSideMenuActive {
        text-decoration: none;
    }
    
    .linkSideMenu{
        color: black
    }
    
    .linkSideMenuActive{
        color:white;
    }
    
    .pointSideMenu :first-child, .activePointSideMenu :first-child{
        margin-right: 3vw;
        margin-left: 6vw;
    }
    
    .pointSideMenu img, .activePointSideMenu img {
        width: 6.5vw;
    }
}
.select {
    width: 100% !important;
    margin-bottom: 3vh;
    margin-top: 3vh;
}

.select select {
    box-sizing: border-box;
    width: 100%;
    background-color: white;
    padding-left: 1vw;
    padding-bottom: 0.5vh;
    color:black;
    font-size: 1.3vw;
    outline: none;
    border: none ;
    border-bottom:  0.1vw solid #C4C4C4;
}

.selectPrompt{
    margin-bottom: 1vh;
}

.select select option {
    background-color:white;
    padding: 10vw;
}

@media (max-width: 1024px){
    .select {
        color: black;
        margin-top: 3vh;
    }
    .select select {
        box-sizing: border-box;
        width: 100%;
        background-color: white;
        padding: 2.1vw;
        font-size: 5vw;
        outline: none;
        border: 0.1vw solid #C4C4C4;
        border-radius: 1vw;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

   
}


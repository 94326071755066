.userSettingsContainer{
    position: relative;
}

.settingsPage{  
    padding-left: 11vw;
    padding-top: 6vh;
}

.settingPageUserInfoContainer{
    display: flex;

    width: 100%;
}

.settingPageUserInfoContainer > :first-child{
    width: 17%;
}

.settingPageUserInfoContainer > :last-child{
    width: 50%;
}

.coupleAreaSettings{
    display: flex;
    width: 100% !important;
}

.coupleAreaSettings > div {
    width: 100%;
}

.coupleAreaSettings > :last-child {
    margin-left: 2vw;
}




.settingsUserAvatar {
    border: 0.1vw solid lightgray;
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.settingsUserAvatar img {
    width: 100%;
    height: 100%;
    
}

.labelSettingsArea{
    color: #C4C4C4;
    margin-bottom: 1.4vh;
    font-size:1vw;
    width: 100% !important;
    margin-left: 0.7vw;
}

.settingsPageForm {
    margin-left: 3vw;
    width: 80vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.BlockNameSettings{
    width: 100% !important;
    font-size: 1.5vw;
    text-transform: uppercase;
    margin-bottom: 2vh;
}

.BlockNameSettingsMandatory{
    font-size: 1vw;
    margin-bottom: 3vh;
}

.settingsSystem{
    margin-top: 9vh;
    margin-left: 18vw;
    margin-bottom: 5vh;
}


.inputContainerSettings{
    margin-left: -0.5vw;
    margin-top: 1vh;;
    width: 100% !important;
}

.settingsUserProfileButtons{
    display: flex;
    margin-left:22vw;
    width: 50%;
}

.settingsUserProfileButtons :first-child{
    width: 100%;
}


.settingsUserProfileButtons :last-child{
    margin-left: 1vw;
    width: 100%;
}

.buttonCallback{
    font-size: 1vw;
    margin-top: 2.5vh;
    font-weight: normal;
    padding: 1.5vh 1vw;
    border: none;
    display: flex;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-align: center;
    background-color: #5F8BD9;
}

.buttonCallback a {
    text-decoration: none;
    color: white;
    width: 100%;
    height: 100%;
}


.mobileNotDisplay{
    display: block;
}

.buttonForAddImage{
    text-align: center;
    font-size: 1.1vw;
    cursor: pointer;
    width: 100% !important;
}

.settingsButtons {
    width: fit-content;
    display: flex;
    flex-direction: column;
}

.settingsButtons > * {
    width: 100%
}

.buttonDeleteUser {
    border: 1px solid #5F8BD9;
    border-radius: 5px;
    margin-top: 20px;
    padding: 5px 10px;
    cursor: pointer;
    white-space: nowrap;
    color: #5F8BD9;
    font-weight: normal;
    font-size: 1vw;
    text-align: center;
}

@media (max-width: 1024px){

    .settingsPage{  
        padding: 1vh 4vw;
    }

    .settingPageUserInfoContainer{
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    
    .coupleAreaSettings{
        flex-direction: column;
    }

    .settingPageUserInfoContainer > :first-child{
        width: 100%;
    }

    .settingsUserAvatar{
        width: 100% !important;
        border: none;
        cursor: pointer;
        border-bottom: 0.1vw solid #C4C4C4;
    }

    .buttonForAddImage{
        display: none;
    }

    .BlockNameSettings{
        font-size: 7vw;
        margin-bottom: 1vh;
        margin-top: 2vh;
    }

    .BlockNameSettingsMandatory{
        font-size: 4vw;
        margin-bottom: 2vh;
    }

    .settingsPageForm{
        width: 100% !important;
        margin: 0;
    }

    .settingsUserAvatar img {
        margin-bottom: 5vh;
        width: 100%;
    }

    .labelSettingsArea{
        font-size: 4vw;
        margin-bottom: -2vh;
    }

    .coupleAreaSettings > :last-child {
        margin: 0;
    }

    .settingsUserProfileButtons{
        width: 100% !important;
        margin: -1vh 0;
        display: flex;
        justify-content: center;
        align-items:center;
    }
    
    .settingsSystem{
        margin-top: 5.5vh !important;
        margin: 0;
        font-size: 3.5vw;
    }

    .buttonCallback{
        font-size: 4vw;
        padding: 1vh 1vw;
    }

    .buttonDeleteUser {
        font-size: 4vw;
        padding: 1vh 1vw;
    }

    .versionApp{
        margin : 3vh 0;
        font-size: 3.8vw;
    }

    .mobileNotDisplay{
        display: none;
    }
}
.SignUp{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.SignUpForm{
    width: 25vw;
    height: 30vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.SignUpFormText{
    margin-bottom: 3vh;
    font-size: 2vw;
    font-weight: bold;
}

.NavigationLink{
    text-decoration: none;
    color:black;
    font-size: 1vw;
    margin-top: 2vh;
}

.NavigationLink:hover{
    transition: 0.5s;
    color: #798F96;
}

.agreeButtonsSignUp{
    display: grid;
    grid-gap: 1vh 0;
    grid-auto-rows: auto;
    grid-template-columns: auto auto;
    width: 100%;
    font-size: 0.8vw;
    box-sizing: border-box;
}

.agreeButtonsSignUp > input {
    grid-column: 1;
}

.agreeButtonsSignUp > div {
    grid-column: 2;
}

.agreeButtonsSignUp input {
    align-self: center;
    width: 1.1vw;
    height: 1.1vw;
}

@media (max-width: 1024px){ /* if mobile */
  
    @supports (-webkit-appearance: none) { /* if safari */
        .SignUp {
            padding-top: 10vh;
            padding-bottom: 15vh;
        }
    }

    .NavigationLink{
        text-decoration: none;
        color: black;
        font-size: 4vw;
        margin-top: 2.5vh;
    }  
    .SignUpForm{
        width: 90vw;
        height: 30vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .SignUpFormText{
        margin-bottom: 3vh;
        font-size: 6vw;
        font-weight: bold;
    }

    .agreeButtonsSignUp{
        font-size: 3.3vw;
        grid-gap: 1vh 1vw;
    }

    .agreeButtonsSignUp input {
        width: 5vw;
        height: 5vw;
        padding: 0;
    }
}
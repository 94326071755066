.personalReportHeadingBlock{
    background: rgba(146, 227, 218, 0.3);
    display: flex;
    width: 100%;
    margin: 3vh 0;
    padding: 3vh 1vw;
    border-radius: 5px;
    box-sizing: border-box;
}

.personalReportHeadingBlock > div{
    width: 50%;
}

.personalReporstHeadingBlockText :first-child{
    font-size: 2vw;
    margin-top: 3vh;
    margin-bottom: 2vh;
}

.headingImageBlock img {
    width: 23vw;
    margin-left: 13vw;
}


@media (max-width: 1024px){
    .personalReportHeadingBlock{
        display: none;
    }
}
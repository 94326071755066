.SignIn{
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
}

.couplesLinks{
    display: flex;
    width: 100%;
    margin-top: -1vh;
}
.couplesLinks > div {
    width: 50%;
}

.couplesLinks :first-child{
    display: flex;
    justify-content: start !important;
}

.couplesLinks :last-child{
    display: flex;
    justify-content: flex-end;
}




.SignInForm{
    width: 25vw;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.SignInFormText{
    margin-bottom: 3vh;
    font-size: 2vw;
    font-weight: bold;
    text-align: center;
}

.SignInFormText img {
    width: 25vw;
}

.ErrorPromptAuth{
    font-size: 1vw;
    color: #dc3545;
}

@media (max-width: 1024px){
    .SignInForm{
        width: 90vw;
        height: 60vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .SignInFormText{
        margin-bottom: 3vh;
        font-size: 6vw;
        font-weight: bold;
        text-align: center;
    }

    .SignInFormText img {
        width: 55vw;
    }

    .ErrorPromptAuth{
        font-size: 3.6vw;
        color: #dc3545;
    }
}

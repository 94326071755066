/* styles from settings ui*/

.dataScopeLink {
    margin-top: 9vh;
}

.dataScopeLinkHeader {
    width: 100% !important;
    font-size: 1.5vw;
    text-transform: uppercase;
    margin-bottom: 2vh;
}

.dataScopeLinkBreakContainer {
    width: 50%;
    padding-left: 17%;
    margin-left: 3vw;
}

.dataScopeLinkBreak {
    margin-left: auto;
    margin-right: 0;
    width: fit-content;
    cursor: pointer;
}

.dataScopeLinkSettings .NotActiveInput {
    margin-top: 1vh;
    margin-bottom: 0.5vh;
}

@media (max-width: 1024px) {
    .dataScopeLinkHeader {
        margin-top: 5.5vh !important;
        margin: 0;
        font-size: 3.5vw;
    }

    .dataScopeLinkSettings .NotActiveInput {
        margin-top: 2vh;
        margin-bottom: 1.5vh;
    }

    .dataScopeLinkBreakContainer {
        width: auto;
    }

    .dataScopeLinkBreak {
        font-size: 1.5vw;
    }
}
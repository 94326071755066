


.croppedPopUp, .circleCroppedPopUp{
    box-sizing: border-box;
    position: fixed !important;
    z-index: 1000000000000000000;
    border-radius: 0.2vw;
    width: 50vw;
    max-height: 90vh;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.croppedBlockBody{
    text-align: center;
    padding: 2vh 2vw !important;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.circleCroppedPopUp .cropper-crop-box, .circleCroppedPopUp .cropper-view-box {
    border-radius: 50%;
    width: 100%;
    height: 100%;
}

.circleCroppedPopUp .circleCroppedPopUp.cropper-view-box {
    box-shadow: 0 0 0 1px #39f;
    outline: 0;
}

.promptCroppedPopUp{
    font-size: 1.1vw;
    margin-top: 2vh;
    margin-bottom: 2vh;
}

.croppedAreaBlock{
    max-width: 70%;

}


.buttonCoupleImageLoad{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 2vh;
    margin-bottom: 2vh;
    width: 70%;
}

.buttonCoupleImageLoad input {
    padding: 2vh 0;
}
.buttonCoupleImageLoad > * {
    width: 45%;
}


@media (max-width: 1024px){
    .croppedPopUp, .circleCroppedPopUp{
        box-sizing: border-box;
        position: fixed !important;
        z-index: 1000000000000000000;
        border-radius: 0.2vw;
        width: 90vw;
        max-height: 90vh;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .croppedBlockBody{
        text-align: center;
        padding: 1vh 1vw !important;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        overflow-y: auto;
    }

    .promptCroppedPopUp{
        font-size: 3.5vw;
        margin-top: 2vh;
        margin-bottom: 2vh;
    }

    .croppedAreaBlock{
       width: 100%;
    }
    
    
    .buttonCoupleImageLoad{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        margin-top: 2vh;
        margin-bottom: 2vh;
        width: 100%;
    }

    .buttonCoupleImageLoad input,  .buttonCoupleImageLoad label{
        font-size: 3vw !important;
        padding: 3vw 0;
    }
    
    .buttonCoupleImageLoad > * {
        width: 45%;
    }
    
}